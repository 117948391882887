@font-face {
  font-family: "PastiO";
  src: url("../fonts/PastiO.otf") format("truetype");
  src: url("../fonts/PastiR.otf") format("opentype");
  /* Add other font formats if available (e.g., woff, woff2) */
  /* Add different font weights and styles if applicable */
  /* font-weight: normal; */
  /* font-style: normal; */
}
.homeBg {
  /* background-image: url(../images/lightStrip.jpg); */
  filter: blur(2px);
  filter: grayscale(10%);
}
.homeBannerTxt {
  color: black;
  text-align: center;
  font-family: PastiO, sans-serif;
  white-space: prewrap;
  background-color: white;
  border: 2px solid black;
  text-align: center;
  box-shadow: -10px 10px 4px 5px rgba(0,0,0,0.1);
}
html {
    background: linear-gradient(to right, #EBF8FF, #EEF2FF);
}
@keyframes fadeIn {
  from{
    opacity : 0%;
    transform : translateX(-50px);
  }
  to{
    opacity : 1;
    transform : translateX(0)
  }
}
.fadeIn { 
  animation : fadeIn 1.4s ease-in;
}
.closedNavBtns li{
  padding: 10px;
}
.fa-instagram{
  /* background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); */
  font-size: 30px;
  padding-left:1.5px;
  padding-right:2.5px;
}
.fab{
  /* box-shadow: 0px 3px 10px rgba(0,0,0,.25); */
  border-radius: 5px;
}
.bannerRtSide{
  height: 100%; /* Or any specific height you desire */
  background-size: 100% auto; /* Ensures the width fills the div and the height adjusts accordingly */
  background-position: right center;
  background-repeat: no-repeat; /* Prevents the background image from repeating */
}

.stayConnected{
  font-family: PastiO, sans-serif;
}
.leftSideTextCommitmentSect {
  font-family: PastiR, sans-serif;
}
.leftSideTextCommitmentSect h2{
  font-family: PastiO, sans-serif;
}
@keyframes drive {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-125vw);
  }
}

.animate-drive {
  animation: drive 20s linear infinite;
}