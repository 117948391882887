@keyframes fade-in {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes card-enter {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  .animate-fade-in {
    animation: fade-in 1s ease-out forwards;
  }
  
  .animate-card-enter {
    animation: card-enter 0.6s ease-out forwards;
    opacity: 0;
  }
  
  .hover\:scale-105:hover {
    transform: scale(1.05);
  }