.navStyles{
    font-family: PastiO, sans-serif;
    position:fixed;
    top: 0;
    z-index: 100;
  }
  .dropdown {
    transition: width 2s ease-in-out;
    opacity: 0.90;
  }
  .dropdown li{
      margin-bottom: 15px;
  }
  .navRtSide li{
      margin-right: 15px;
      font-size: 18px;
  }
  .dropdown a{
    padding: 5px;
    background-color: #1e41ee;
  }